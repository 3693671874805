import React from 'react';
import { Layout } from '../components/Layout';
import { PrivateRoute } from '../components/PrivateRoute';
import { RegisterNewModel } from '../modules/Models';

const registerNewModelWrapper = () => (
  <Layout>
    <RegisterNewModel />
  </Layout>
);

const registerNewModelPage = ({ ...props }) => (
  <PrivateRoute location={props.location.pathname} component={registerNewModelWrapper} />
);

export default registerNewModelPage;

import { axiosInstanceWithAuth } from '../../../services/axios-instance';

interface CreateProps {
  type: number;
  brandName: string;
  name: string;
  version: string;
  fabricationYear: number;
  modelYear: number;
}

export const models = {
  create: async (params: CreateProps) => {
    return await axiosInstanceWithAuth
      .post('/model', { ...params })
      .then(resp => resp)
      .catch(err => err);
  },
};

import { axiosInstanceWithAuth } from '../../../services/axios-instance';

export const Brands = {
  getMigrationBrands: (type?: number | string) =>
    axiosInstanceWithAuth
      .get('/brand', { params: { type } })
      .then(res => {
        return res.data;
      })
      .catch(err => console.error(err)),
};

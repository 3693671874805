import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Button } from '../../../../components/Button';
import { Icon } from '../../../../components/Icons';
import {
  ModalSuccessAndError,
  modalErrorDefault,
  modalSuccesDefault,
} from '../../../../components/ModalSuccessAndError';
import { Select } from '../../../../components/Select';
import { TextInput } from '../../../../components/TextInput';
import { mapValueToObjectLabelValue, unique } from '../../../../utils/functions';
import { onlyNumbers } from '../../../../utils/mask';
import { Brands } from '../../../Brands/services/Brands';
import { models } from '../../services/Models';
import styles from '../../styles.module.css';

enum VehicleTypes {
  CARRO = 1,
  MOTO = 2,
  ONIBUS = 3,
  CAMINHAO = 4,
  NAUTICO = 5,
  AGRICOLA = 6,
}

export const RegisterModelForm = () => {
  const [type, setType] = useState(1);
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [version, setVersion] = useState('');
  const [fabricationYear, setFabricationYear] = useState('');
  const [modelYear, setModelYear] = useState('');

  const [loading, setLoading] = useState(false);
  const [configModal, setConfigModal] = useState(modalErrorDefault);
  const [successOrErrorOpen, setSuccessOrErrorOpen] = useState(false);
  const [brandOptions, setBrandOptions] = useState([] as { value: string; label: string }[]);

  const [errorModel, setErrorModel] = useState('');
  const [errorVersion, setErrorVersion] = useState('');
  const [errorFabricationYear, setErrorFabricationYear] = useState('');
  const [errorModelYear, setErrorModelYear] = useState('');

  // States - autocompletes
  const [filterModels, setFilterModels] = useState<any>([]);
  const [modelos, setModelos] = useState<any>([]);
  const [versoes, setVersoes] = useState<any>([]);
  const [filterVersion, setFilterVersions] = useState<any>([]);

  useEffect(() => {
    setTimeout(() => {
      Axios.get(`${process.env.BASE_API_DEV}/model/findByBrand/${brand}?type=${type}`).then(
        response => setModelos(response.data),
      );
    }, 5000);
  }, []);

  useEffect(() => {
    Axios.get(`${process.env.BASE_API_DEV}/model/findByBrand/${brand}?type=${type}`).then(
      response => setModelos(response.data),
    );
    setModel('');
  }, [brand]);

  useEffect(() => {
    Axios.get(`${process.env.BASE_API_DEV}/model/findByBrandModel/${brand}/${model}?type=1`).then(
      response => {
        setVersoes(response.data);
      },
    );
  }, [model]);

  useEffect(() => {
    setVersion('');
  }, [model]);

  const updateBrandOptions = async () => {
    await Brands.getMigrationBrands(type)
      .then(response => {
        const brands = unique(mapValueToObjectLabelValue(response, 'name'));
        setBrandOptions(brands);
        setBrand(brands[0].value);
      })
      .catch(err => console.error(err));
  };

  const validateModel = () => {
    if (model === '') {
      setErrorModel('Campo Obrigatório');
      return false;
    }

    setErrorModel('');
    return true;
  };

  const validateVersion = () => {
    if (version === '' && type === VehicleTypes.CARRO) {
      setErrorVersion('Campo obrigatório');
      return false;
    }

    setErrorVersion('');
    return true;
  };

  const validateFabricationYear = () => {
    if (fabricationYear === '') {
      setErrorFabricationYear('Campo obrigatório');
      return false;
    } else if (fabricationYear.length < 4) {
      setErrorFabricationYear('Ano inválido');
      return false;
    }

    setErrorFabricationYear('');
    return true;
  };

  const validateModelYear = () => {
    if (modelYear === '') {
      setErrorModelYear('Campo obrigatório');
      return false;
    } else if (modelYear.length < 4) {
      setErrorModelYear('Ano inválido');
      return false;
    }

    setErrorModelYear('');
    return true;
  };

  const checkErrors = () => {
    const checkModelYear = validateModelYear();
    const checkModel = validateModel();
    const checkFabricationYear = validateFabricationYear();
    const checkVersion = validateVersion();

    return (
      checkModelYear &&
      checkModel &&
      checkFabricationYear &&
      (checkVersion || type !== VehicleTypes.CARRO)
    );
  };

  const controlSucessOrError = () => setSuccessOrErrorOpen(!successOrErrorOpen);

  const clickOkModalSOE = () => {
    setSuccessOrErrorOpen(!successOrErrorOpen);
  };

  useEffect(() => {
    updateBrandOptions();
  }, [type]);

  const handleSelect = (e: any) => {
    setType(e?.value);
  };
  const handleBrand = (e: any) => {
    setBrand(e?.value);
  };
  const handleFabricationYear = (e: any) => {
    setFabricationYear(e?.target?.value);
  };
  const handleModelYear = (e: any) => {
    setModelYear(e?.target?.value);
  };
  const onClick = async () => {
    if (checkErrors()) {
      const params = {
        type: { type }.type,
        brandName: brand,
        name: model,
        version: type === VehicleTypes.CARRO ? version : '',
        fabricationYear: Number(fabricationYear),
        modelYear: Number(modelYear),
      };

      setLoading(true);
      setConfigModal(modalErrorDefault);

      await models.create(params).then(res => {
        if (res.status && res.status >= 200 && res.status < 300) {
          setConfigModal(modalSuccesDefault);
        } else if (res.status && res.status === 409) {
          setConfigModal({
            success: false,
            title: 'Ops!',
            subTitle: 'esse modelo já está cadastrado',
          });
        }
      });

      setLoading(false);
      setSuccessOrErrorOpen(true);
    }
  };

  const setVersions = new Set();

  const filterDuplicateVersions = filterVersion.filter((version: any) => {
    const duplicateVersions = setVersions.has(version.version);
    setVersions.add(version.version);
    return !duplicateVersions;
  });

  const renderVersionInput = () => {
    if (type === VehicleTypes.CARRO) {
      return (
        <>
          <div className="mt16">
            <TextInput
              name="version"
              id="versionInput"
              labeltext="Versão"
              value={version}
              onChange={handleFilterVersion}
              onBlur={validateVersion}
              errorMessage={errorVersion}
            />
          </div>
          {filterVersion && (
            <div className="autocomplete">
              {filterDuplicateVersions.map((version: any, index: any) => (
                <div
                  className="item-model"
                  key={index}
                  onClick={() => setVersionText(version.version)}
                >
                  <p>{version.version}</p>
                </div>
              ))}
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const options = [
    { value: VehicleTypes.CARRO, label: '1 - Carro' },
    { value: VehicleTypes.MOTO, label: '2 - Moto' },
    { value: VehicleTypes.ONIBUS, label: '3 - Ônibus' },
    { value: VehicleTypes.CAMINHAO, label: '4 - Caminhão' },
    { value: VehicleTypes.NAUTICO, label: '5 - Náutico' },
    { value: VehicleTypes.AGRICOLA, label: '6 - Agrícola' },
  ];

  const handleFilter = (e: any) => {
    setModel(e.target.value);

    const filterModel = modelos.filter((value: string) => {
      return value.toLowerCase().includes(e.target.value.toLocaleLowerCase());
    });

    setFilterModels(filterModel);
  };

  const handleFilterVersion = (e: any) => {
    setVersion(e.target.value);

    const filterVersions = versoes.filter((value: any) => {
      return value.version.toLowerCase().includes(e.target.value.toLocaleLowerCase());
    });

    setFilterVersions(filterVersions);
  };

  const setModelText = (modelo: string) => {
    setModel(modelo);
    setFilterModels([]);
  };

  const setVersionText = (version: string) => {
    setVersion(version);
    setFilterVersions([]);
  };

  useEffect(() => {
    if (model === '') {
      setFilterModels([]);
    }
  }, [model]);

  useEffect(() => {
    if (version === '') {
      setFilterVersions([]);
    }
  }, [version]);

  return (
    <div style={{ marginTop: '90px' }}>
      <div style={{ marginLeft: '15%' }}>
        <Icon name="SvgIconEdit" className="mr8 red" />
        <span className="fw7 f20 f30-m f30-l abbey">ADICIONAR NOVO MODELO</span>
      </div>
      <div className={styles.wrapperContent}>
        <div className="mt16">
          <Select
            options={options}
            noDropdownIndicator={false}
            onChange={handleSelect}
            selected={options[0]}
            title="Tipo de veículo"
          />
        </div>

        <div className="mt16">
          <Select
            key={type}
            options={brandOptions}
            selected={brandOptions[0]}
            noDropdownIndicator={false}
            title="Marca"
            onChange={handleBrand}
            placeholder=""
          />
        </div>

        <div className="mt16">
          <TextInput
            name="model"
            id="modelInput"
            labeltext="Modelo"
            value={model}
            onChange={handleFilter}
            onBlur={validateModel}
            errorMessage={errorModel}
          />
        </div>

        {filterModels && (
          <div className="autocomplete">
            {filterModels.map((modelo: any, index: any) => (
              <div className="item-model" key={index} onClick={() => setModelText(modelo)}>
                <p>{modelo}</p>
              </div>
            ))}
          </div>
        )}

        {renderVersionInput()}

        <div className={styles.sameLineInputs}>
          <div className={styles.spaceBetween + ' mr8'}>
            <TextInput
              name="fabricationYear"
              id="fabricationYearInput"
              labeltext="Ano Fabricação"
              value={fabricationYear}
              onChange={handleFabricationYear}
              maxLength={4}
              mask={onlyNumbers}
              onBlur={validateFabricationYear}
              errorMessage={errorFabricationYear}
            />
          </div>

          <div className={styles.spaceBetween + ' ml8'}>
            <TextInput
              name="modelYear"
              id="modelYearInput"
              labeltext="Ano Modelo"
              value={modelYear}
              onChange={handleModelYear}
              maxLength={4}
              mask={onlyNumbers}
              onBlur={validateModelYear}
              errorMessage={errorModelYear}
            />
          </div>
        </div>

        <div style={{ width: '40%', marginTop: '30px' }}>
          <Button loading={loading} classButton="mb24" onClick={onClick}>
            SALVAR
          </Button>
        </div>

        <ModalSuccessAndError
          closeModal={controlSucessOrError}
          isOpen={successOrErrorOpen}
          actionBtn={clickOkModalSOE}
          {...configModal}
        />
      </div>
    </div>
  );
};
